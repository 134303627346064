<template>
  <BreadCrumb PageTitle="Juegos" PageParent="Juegos" parentRoute="/game-list"/>
  <GameList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import GameList from "../../components/Game/GameList/GameList.vue";

export default defineComponent({
  name: "GameListPage",
  components: {
    BreadCrumb,
    GameList,
  }
});
</script>