<template>
  <div ref="mymodal" class="modal fade createNewModal" id="createNewAppVersionModal" tabindex="-1" aria-hidden="true"
    @click.self="handleBackdropClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title fw-bold text-black">Registrar nueva Versión de la aplicación móvil</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div
                  :class="['form-group mb-3', { 'has-error': (!item.version && showError) || showInvalidVersionFormatError }]">
                  <label class="form-label fw-semibold">Número de la versión</label>
                  <input v-model="item.version" type="text" class="form-control" placeholder="1.0.0"
                    @input="validateVersion" />

                  <!-- Mostrar el error si la versión está vacía o si tiene un formato inválido -->
                  <span v-if="!item.version && showError" class="text-danger">Este campo es requerido.</span>
                  <span v-if="showInvalidVersionFormatError" class="text-danger">{{ invalidVersionFormat }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.platform && showError }]">
                  <label class="form-label fw-semibold">Plataforma</label>
                  <select v-model="item.platform" class="form-control" @input="clearError">
                    <option disabled value="">Seleccione una plataforma</option>
                    <option v-for="(el, index) in platformChoices" :value="el.id" :key="index">
                      {{ el.name }}
                    </option>
                  </select>
                  <span v-if="!item.platform && showError" class="text-danger">Seleccione una opción.</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label class="form-label fw-semibold">Fecha de lanzamiento</label>
                  <input v-model="item.releaseDate" type="date" class="form-control" @input="clearError" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3 check-container">
                  <input type="checkbox" v-model="item.isMandatory" class="check-input" />
                  <label class="form-label fw-semibold">¿Es obligatorio actualizar?</label>
                </div>
              </div>
              <div class="col-md-12">
                <div :class="['form-group mb-3', { 'has-error': !item.url && showError }]">
                  <label class="form-label fw-semibold">Url (Enlace de descarga)</label>
                  <input v-model="item.url" type="url" class="form-control" placeholder="https://ejemplo.com" />
                  <span v-if="!item.url && showError" class="text-danger">Este campo es requerido.</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-3">
                  <label class="form-label fw-semibold">Registro de cambios</label>
                  <div class="mb-0">
                    <textarea v-model="item.changelog" class="form-control textarea-spacing"
                      placeholder="Descripción de los cambios de la versión"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
            <div v-if="success" class="alert alert-success mt-3">{{ message }}</div>
            <div class="modal-footer" style="display: flex; justify-content: space-between;">
              <button type="button"
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1"
                data-bs-dismiss="modal" @click="closeModal">Cancelar</button>
              <button type="submit"
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1">
                Registrar versión
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";

export default {
  name: "CreateNewAppVersionModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    id: Number,
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true,
    },
    existingAppVersions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      invalidVersionFormat: "",
      showInvalidVersionFormatError: false,
      showError: false,
      success: false,
      message: '',
      item: {
        id: null,
        version: null,
        platform: '',
        url: "",
        releaseDate: null,
        isMandatory: false,
        changelog: "",
      },
      platformChoices: [
        { id: 'ANDROID', name: 'Android' },
        { id: 'IOS', name: 'iOS' },
      ],
      encodedChangelogText: "",
    };
  },
  watch: {
    id(newId) {
      if (newId) {
        this.getAppVersionData(newId);
      } else if (newId === undefined) {
        this.item = {
          id: null,
          version: null,
          platform: '',
          url: "",
          releaseDate: null,
          isMandatory: false,
          changelog: "",
        };
      }
    },
  },
  methods: {
    async getAppVersionData(id) {
      if (!id) return;
      try {
        await StateStore.actions.actionsGetAppVersions({ axios: this.axios, id });
        this.item = await StateStore.getters.getAppVersionsRetrieve();
        this.item.changelog = decodeURIComponent(this.item.changelog)
      } catch (error) {
        console.error('Error al obtener la versión:', error);
        this.error = 'Error al cargar los datos de la versión.';
      }
    },
    validateVersion() {
      this.invalidVersionFormat = ''; // Limpiar mensaje de error
      this.showInvalidVersionFormatError = false; // Ocultar error al inicio
      this.clearError()

      // Eliminar espacios
      let value = this.item.version.replace(/\s+/g, '');

      // Asegurarse de que no comience con un punto, ni tenga puntos consecutivos
      value = value.replace(/^\./, '') // Eliminar punto al inicio
        .replace(/\.{2,}/g, '.')      // Reemplazar dos puntos consecutivos por un solo punto

      // Solo permitir números y puntos
      this.item.version = value.replace(/[^0-9.]/g, '');

      // Validación para verificar si termina con un punto
      if (this.item.version.endsWith('.')) {
        this.invalidVersionFormat = 'El número de la versión no puede terminar con un punto.';
        this.showInvalidVersionFormatError = true;
      }
    },
    checkUniqueVersion() {
      // Excluir la versión actual en caso de edición
      let existingAppVersion = this.existingAppVersions;

      // Filtrar las versiones existentes para asegurarse de que no haya duplicados, excluyendo la versión en edición
      if (this.id) {
        existingAppVersion = existingAppVersion.filter(item => item.platform !== this.item.platform || item.version !== this.item.version);
      }

      // Verificar si ya existe una combinación de platform y version
      const isDuplicate = existingAppVersion && existingAppVersion.length > 0 ? existingAppVersion.some(item => item.platform === this.item.platform && item.version === this.item.version) : false;

      if (isDuplicate) {
        this.invalidVersionFormat = 'Ya existe una versión con esta combinación de plataforma y número de versión.';
        this.showInvalidVersionFormatError = true;
      } else {
        this.showInvalidVersionFormatError = false;
      }
    },
    async submitForm() {
      this.showError = true;
      if (!this.item.version || !this.item.platform || !this.item.url) {
        this.error = 'Rellene los campos requeridos';
        return;
      }

      this.checkUniqueVersion()

      // Codigicar changelog para permitir caracteres especiales
      if (this.item.changelog) {
        this.encodedChangelogText = encodeURIComponent(this.item?.changelog);
      }

      this.error = '';
      this.showError = false;
      this.success = true;
      try {
        if (!this.id) {
          // Creación
          await StateStore.actions.actionsCreateAppVersions({
            axios: this.axios,
            version: this.item.version,
            platform: this.item.platform,
            url: this.item.url,
            releaseDate: this.item.releaseDate,
            isMandatory: this.item.isMandatory,
            changelog: this.encodedChangelogText,
          });
          this.message = 'Versión registrada con éxito...';
        } else {
          // Edición
          await StateStore.actions.actionsEditAppVersions({
            axios: this.axios,
            id: this.id,
            version: this.item.version,
            platform: this.item.platform,
            url: this.item.url,
            releaseDate: this.item.releaseDate,
            isMandatory: this.item.isMandatory,
            changelog: this.encodedChangelogText,
          });
          this.message = 'Versión modificada con éxito...';
        }
        this.$emit('app-version-saved');
        this.closeModal();
        location.reload();
      } catch (error) {
        console.error('Error al procesar la solicitud:', error);
        this.error = 'Ocurrió un error al procesar la solicitud.';
      }
    },
    clearError() {
      this.error = '';
    },
    handleBackdropClick() {
      if (this.noCloseOnBackdrop) return;
      this.closeModal();
      this.resetForm();
      this.clearError()
      this.showInvalidVersionFormatError = false
      this.invalidVersionFormat = ''
    },
    resetForm() {
      this.item = {
        id: null,
        version: null,
        platform: '',
        url: "",
        releaseDate: null,
        isMandatory: false,
        changelog: "",
      };
      this.error = '';
      this.success = false;
      this.showError = false;
      this.message = '';
      this.encodedChangelogText = '';
    },
    closeModal() {
      this.clearError()
      this.showInvalidVersionFormatError = false
      this.invalidVersionFormat = ''
      this.$refs.mymodal.classList.remove('show');
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove(); // Eliminar el fondo del modal
      }
      this.resetForm();
      this.$emit('close');
    },
    onMultiselectOpen() {
      this.multiselectPlaceholderText = "Escriba aquí para buscar";
    },
    onMultiselectClose() {
      this.multiselectPlaceholderText = "Seleccione una o varias ciudades";
    },
  },
  async created() {
    if (this.id) {
      this.getAppVersionData(this.id);
    }
  },
};
</script>

<style scoped>
.has-error input,
.has-error select {
  border-color: red;
}

.alert-success {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.alert-danger {
  background-color: red;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.textarea-spacing {
  line-height: 1.2;
  min-height: 150px;
  max-height: 5000px;
  overflow-y: auto;
}

.check-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.check-input {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  accent-color: #05906e;
  /* Cambiar el color del checkbox en Firefox y algunos otros navegadores */
}

.check-input:checked {
  background-color: #05906e;
  border-color: #05906e;
}

/* .check-input:checked+label {
  color: #05906e;
} */

.textarea-spacing {
  line-height: 1.2;
  /* Espaciado entre líneas */
  min-height: 150px;
  max-height: 5000px;
  /* Altura máxima */
  overflow-y: auto;
  /* Mostrar barra de desplazamiento cuando exceda la altura */
}
</style>
