<template>
  <BreadCrumb PageParent="Secciones Educativas" PageTitle="Secciones Educativas" parentRoute="/educ-sections-list"/>
  <EducationalSectionsList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import EducationalSectionsList
  from "../../components/EducationalSections/EducationalSectionsList/EducationalSectionsList.vue";

export default defineComponent({
  name: "EducSectionsListPage",
  components: {
    BreadCrumb,
    EducationalSectionsList,
  }
});
</script>