import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class CategoryElemetosGastos {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `{
              categoryElementosRetrieve(id:${id}) {
                id
                name
                section {
                  id
                  name
                }
              }
            }
            `,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.categoryElementosRetrieve
            ) {
                response.data.data.categoryElementosRetrieve.compressed = {
                    section: response.data.data.categoryElementosRetrieve.section,
                };
            }

            response.data.data.categoryElementosRetrieve.section = response.data.data.categoryElementosRetrieve?.section?.id

            return response.data.data.categoryElementosRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  categoryElementosList{
                    id
                    name
                    section{
                      id
                      name
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("categoryElementosList: ", response.data.data?.categoryElementosList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.categoryElementosList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteUser ($id: ID!){
                      deleteUser(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteUser.ok;
    }

    static async create(
        axios: AxiosInstance,
        name: string,
        section: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  createCategoryElemento(newCategoryelementos:{
                    name:"${name}",
                    section:${section}
                  }){
                    categoryelementos{
                      id
                      name
                      section{
                        id
                        name
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createCategoryElemento.ok) {
                await StateStore.actions.actionsSetNewIdUser(response.data.data.createCategoryElemento.categoryelementos.id);
                return response.data.data.createCategoryElemento.ok;
            } else if (response.data.data.createCategoryElemento.errors) {
                const errores = response.data.data.createCategoryElemento.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createCategoryElemento.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        name: string,
        section: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateCategoryElemento(newCategoryelementos:{
                    id:${id},
                    name:"${name}",
                    section:${section}
                  }){
                    categoryelementos{
                      id
                      name
                      section{
                        id
                        name
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateCategoryElemento.ok) {
                return response.data.data.updateCategoryElemento.ok;
            } else if (response.data.data.updateCategoryElemento.errors) {
                const errores = response.data.data.updateCategoryElemento.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateCategoryElemento.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
