import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class ElemetosGastos {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
              elementosGastosRetrieve(id:${id}){
                id
                image
                name
                type{
                  id
                  name
                  value
                }
                categoryElementos{
                  id
                  image
                  name
                }
                
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.elementosGastosRetrieve
            ) {
                response.data.data.userRetrieve.compressed = {
                    types: response.data.data.elementosGastosRetrieve.types,
                    categoryElementos: response.data.data.elementosGastosRetrieve.categoryElementos,
                };
            }

            // response.data.data.elementosGastosRetrieve.types = response.data.data.userRetrieve?.roles?.id

            return response.data.data.elementosGastosRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              elementosGastosList{
                    id
                image
                name
                type{
                  id
                  name
                  value
                }
                categoryElementos{
                  id
                  image
                  name
                }
            
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("elementosGastosList: ", response.data.data?.elementosGastosList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.elementosGastosList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteUser ($id: ID!){
                      deleteElementoGastos(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteElementoGastos.ok;
    }

    static async create(payload) {
        const response = await payload.axios?.post(
            "/graphql/",
            `mutation{
                  createElementoGastos(newElementosgastos:{
                    name:"${payload.name}"
                    categoryElementos:${payload.categoryElementos}
                    type:${payload.type}
                    frecuency:${payload.frecuency}
                    time:${payload.time}                    
                  }){
                    
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createElementoGastos.ok) {
                return response.data.data.createElementoGastos.ok;
            } else if (response.data.data.createElementoGastos.errors) {
                const errores = response.data.data.createElementoGastos.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createElementoGastos.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(payload: any) {
        const response = await payload.axios?.post(
            "/graphql/",
            `mutation{
                  updateElementoGastos(newElementosgastos:{
                    id:${payload.id}
                    name:"${payload.name}"
                    categoryElementos:${payload.categoryElementos}
                    type:${payload.type}
                    frecuency:${payload.frecuency}
                    time:${payload.time}                    
                  }){
                    
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateElementoGastos.ok) {
                return response.data.data.updateElementoGastos.ok;
            } else if (response.data.data.updateElementoGastos.errors) {
                const errores = response.data.data.updateElementoGastos.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateElementoGastos.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
