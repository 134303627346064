import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Roles {
    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              rolesList{
                results{
                  id
                  name
                  userRole{
                    id
                    username
                    email
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("roles: ", response.data.data?.rolesList.results);
        return response.data.data?.rolesList?.results;
    }

    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios
            .post(
                "/graphql/",
                `query{
                  rolesRetrieve(id:${id}){
                    id
                    name
                    rolesPermission{
                      id
                      name
                    }
                  }
                }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    // console.log("primer strike");
                    return null;
                } else if (error.request) {
                    // console.log("segudo strike");

                    return null;
                } else {
                    // console.log("3er strike");
                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.rolesRetrieve
            ) {
                response.data.data.rolesRetrieve.compressed = {
                    permission: response.data.data.rolesRetrieve.rolesPermission.map((rolesPermission) => rolesPermission),
                };
            }
            return response.data.data.rolesRetrieve;
        }
    }
    static async edit(
        axios: AxiosInstance,
        id: number,
        name: string,

    ) {

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateRole(newRoles:{
                    id: ${id}
                    name: "${name}"
                  }){
                    roles{
                      id
                      name
                      rolesPermission{
                        id
                        name
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateRole.ok) {
                return response.data.data.updateRole.ok;
            } else if (response.data.data.updateRole.errors) {
                const errores = response.data.data.updateRole.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateRole.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        }
        else {
            return null;
        }
    }

    static async create(
        axios: AxiosInstance,
        name: string,

    ) {

        const response = await axios?.post(
            "/graphql/",
            `mutation{
              createRoles(newRoles:{
                name: "${name}"
              }){
                ok
                errors{
                  field
                  messages
                }
                roles{
                  id
                  name
                  rolesPermission{
                    id
                    name
                  }
                  userRole{
                    id
                    username
                    email
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createRoles.ok) {
                return response.data.data.createRoles.ok;
            } else if (response.data.data.createRoles.errors) {
                const errores = response.data.data.createRoles.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createRoles.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        }
        else {
            return null;
        }
    }
}