<template>
  <div class="card mb-25 border-0 rounded-0 bg-white stats-box" style="min-height: 205px;">
    <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
      <div class="d-flex align-items-center">
        <div class="title ms-15">
          <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
            Total de jugadores
          </span>
          <h1 class="fw-black mb-8 lh-1 mt-30">
            {{ totalPlayers }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";

export default defineComponent({
  name: "StatsBoxes",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  data() {
    return {
      totalPlayers: 0,
    };
  },
  methods: {
    async getTotalPlayers() {
      await StateStore.actions.actionsGetAllUsersGamerList({ 'axios': this.axios });
      const players = StateStore.getters.getUsersGamerList();
      this.totalPlayers = players?.length;
    }
  },
  created() {
    this.getTotalPlayers();
  },
});
</script>
