<template>
  <div class="file-upload text-center position-relative">
    <div class="preview-container">
      <div v-for="(image, index) in imageUrls" :key="index" class="image-preview-container">
        <img :src="image.url" alt="Ficheros" class="img-thumbnail"
          style="width: auto; height: 100px; margin-bottom: 10px;" />
        <button type="button" @click="removeImage(index)" class="btn-remove">Eliminar</button>
      </div>
    </div>
    <div class="text-end me-12">
      <button type="button" @click="triggerFileInput" class="btn btn-primary">
        Subir Ficheros
      </button>
      <input type="file" class="file-input" ref="fileInput" @change="handleFileChange" multiple />
      <!-- <p v-if="fileLimitReached" class="text-danger">Solo puedes subir un máximo de 3 archivos.</p> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import * as StateStore from "@/utils/store";

export default defineComponent({
  name: "ImageUpload",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    initialFiles: {
      type: Array as PropType<{ id: string; name: string }[]>,
      default: () => [],
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedFiles: [] as File[],
      imageUrls: [] as { url: string; text: string }[],
      // fileLimitReached: false,
      bucketName: process.env.VUE_APP_BUCKET_NAME,
      prevId: null as string | null,
      // totalFiles: 0,
      localInitialFiles: [] as { id: string; name: string }[],
      // initialFilesCount: 0,
      remainingInitialFileIds: [] as string[],
    };
  },
  watch: {
    initialFiles: {
      immediate: true,
      handler(newFiles) {
        // Verificar que existe el id, y que sea distinto cada vez que se ejecute el código
        if (this.itemId && this.itemId !== this.prevId && newFiles && newFiles.length > 0) {

          // Limpiar las imágenes previas
          this.imageUrls = [];
          this.localInitialFiles = this.initialFiles
          this.remainingInitialFileIds = this.initialFiles.map(file => file.id);

          // Recorrer los archivos nuevos y generar las URLs de las imágenes
          // newFiles.slice(0, 3).forEach(file => { // Solo procesar hasta 3 archivos
          newFiles.forEach(file => { // Solo procesar hasta 3 archivos
            // Asegurar de que el archivo tenga la propiedad 'name'
            if (file && file.name) {
              // Construir la URL de la imagen usando el nombre del archivo
              const imageUrl = `https://${this.bucketName}.s3.amazonaws.com/Files/${file.name}`;
              this.imageUrls.push({ url: imageUrl, text: "" });
              // this.initialFilesCount = this.imageUrls.length
            } else {
              console.error("El archivo no tiene el nombre necesario:", file);
            }
          });
        }

        // Asegurar que se inicialice de nuevo el arreglo de archivos si se vuelve a editar el mismo tópico
        if (this.itemId) {
          this.prevId = this.itemId;
        } else {
          this.prevId = null;
        }
      }
    }
  },
  methods: {
    triggerFileInput() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.click();
    },
    // resetFileLimit() {
    //   this.fileLimitReached = false;
    //   this.initialFilesCount = 0;
    // },
    resetInput() {
      this.selectedFiles = [];
      this.imageUrls = [];
    },
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        const files = Array.from(input.files).filter(file => file.type.startsWith('image/'));
        // this.totalFiles = this.selectedFiles.length + files.length + this.initialFilesCount;

        // if (this.totalFiles > 3) {
        //   this.fileLimitReached = true;
        //   input.value = ''; // Restablecer el valor del input
        //   return;
        // }

        // this.fileLimitReached = false;

        // Agregar los nuevos archivos a la lista existente, evitando duplicados
        files.forEach((file) => {
          const isDuplicate = this.selectedFiles.some(existingFile => existingFile.name === file.name);
          if (!isDuplicate) {
            this.selectedFiles.push(file);
          }
        });

        // Actualizar las imágenes de vista previa
        this.previewImages();

        this.$emit('files-selected', this.selectedFiles);
      }
    },
    previewImages() {
      // No vaciamos `imageUrls` para que las imágenes iniciales se mantengan
      this.selectedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target?.result) {
            // Solo agregar la imagen si no existe en `imageUrls` para evitar duplicados
            if (!this.imageUrls.some(img => img.url === event.target?.result)) {
              this.imageUrls.push({ url: event.target.result as string, text: "" });
            }
          }
        };
        reader.readAsDataURL(file);
      });
    },
    async uploadImages() {
      if (this.selectedFiles?.length === 0) return;

      const formData = new FormData();
      this.selectedFiles.forEach((file, index) => {
        formData.append("images[]", file);
        formData.append(`texts[${index}]`, this.imageUrls[index]?.text || "");
      });

      try {
        const response = await StateStore.actions.actionsDeleteArticles({
          axios: this.axios,
          formData,
        });

        if (response === null) {
          console.error("Image upload failed.");
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
    removeImage(index: number) {
      // Verificar si el índice pertenece a los archivos iniciales
      // if (index < this.initialFilesCount) {
      const fileId = this.localInitialFiles[index].id; // Obtener el ID del archivo inicial

      // Eliminar de la lista de archivos iniciales (no los elimina de selectedFiles)
      this.localInitialFiles = this.localInitialFiles.filter(file => file.id !== fileId);
      this.remainingInitialFileIds = this.localInitialFiles.map(file => file.id);
      // this.initialFilesCount--;

      // Eliminar la imagen de la vista previa
      this.imageUrls.splice(index, 1);
      // } else {
      // Si no es un archivo inicial, eliminar de selectedFiles y imageUrls
      this.imageUrls.splice(index, 1);
      this.selectedFiles.splice(index, 1);
      // }

      // Verificar si el límite de archivos fue alcanzado
      // this.fileLimitReached = this.selectedFiles.length + this.initialFilesCount >= 3;
    }
  },
});
</script>

<style scoped>
.preview-container {
  display: flex;
  flex-wrap: wrap;
}

.image-preview-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 20px 20px 0;
  align-items: center;
}

.btn-remove {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 20px;
  cursor: pointer;
}


.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.text-end {
  position: relative;
}

.text-danger {
  color: red;
}
</style>
