import { AxiosInstance } from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class EducationalSections {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                  educationalSectionsRetrieve(id:${id}){
                    id
                    name
                    ico{
                        id
                        name
                    }
                    topics{
                      id
                      name
                      text
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            // if (
            //     response.data.data.educationalSectionsRetrieve
            // ) {
            //     response.data.data.userRetrieve.compressed = {
            //         roles: response.data.data.userRetrieve.roles,
            //     };
            // }
            //
            // response.data.data.userRetrieve.roles = response.data.data.userRetrieve?.roles?.id

            return response.data.data.educationalSectionsRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  educationalSectionsList{
                    id
                    name
                    ico{
                        id
                        name
                    }
                    topics{
                      id
                      name
                      text
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("educationalSectionsList: ", response.data.data?.educationalSectionsList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.educationalSectionsList;
    }


    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteEducationalSections ($id: ID!){
                      deleteEducationalSections(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteEducationalSections.ok;
    }

    static async create(
        axios: AxiosInstance,
        name: string,
        ico: number[],
    ) {
        const filesArray = JSON.stringify(ico);
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  createEducationalSections(newEducationalsections:{
                    name: "${name}"
                    ico:${filesArray}
                  }){
                    educationalsections{
                      id
                      name
                      ico{
                        id
                        name                      
                      }
                      topics{
                        id
                        name
                        text
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createEducationalSections.ok) {
                // await StateStore.actions.actionsSetNewIdUser(response.data.data.createUser.customuser.id);
                return response.data.data.createEducationalSections.ok;
            } else if (response.data.data.createEducationalSections.errors) {
                const errores = response.data.data.createEducationalSections.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createEducationalSections.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        name: string,
        ico: number[],
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateEducationalSections(newEducationalsections:{
                    id:${id}
                    name: "${name}"
                    ico: ${JSON.stringify(ico)}
                  }){
                    educationalsections{
                      id
                      name
                      ico{
                        id
                        name
                      }
                      topics{
                        id
                        name
                        text
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateEducationalSections.ok) {
                return response.data.data.updateEducationalSections.ok;
            } else if (response.data.data.updateEducationalSections.errors) {
                const errores = response.data.data.updateEducationalSections.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateEducationalSections.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
