<template>
  <div class="only card mb-25 border-0 rounded-0 welcome-box">
    <div class="card-body pb-0 pt-0">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="title position-relative">
            <h1 class="fw-semibold mb-8 text-white">
              Jugadores
            </h1>
            <h2 class="d-block  text-white fs-md-15 fs-lg-16 mt-30 ms-15">
              "Guardianes del Agua"
            </h2>
          </div>
          <!-- <ul class="ps-0 mb-0 list-unstyled">
            <li class="d-inline-block text-uppercase text-white fw-medium fs-13  position-relative">
              Jugadores
              <span class="d-block fw-black lh-1 text-white  mt-5 mt-md-10">
                {{ gamer }}
              </span>
            </li>
            <li class="d-inline-block text-uppercase fw-medium text-white fs-13  position-relative">
              Ganadores
              <span class="d-block fw-black lh-1 text-white  mt-5 mt-md-10">
                {{ winner }}
              </span>
            </li>
          </ul> -->
        </div>
        <div class="col-lg-6 col-md-6 text-end pe-50">
          <img src="../../../assets/images/logo-no-bg.png" alt="welcome-image" style="height: 200px; width: auto; padding: 15px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";

export default {
  name: "WhatHappening",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  data() {
    return {
      winner: 0,
      gamer: 0,
    }
  },
  methods: {
    async getData() {
      await StateStore.actions.actionsGetAllUsersGamerList({ 'axios': this.axios });
      this.gamer = StateStore.getters.getUsersGamerList()?.length;
      await StateStore.actions.actionsGetAllUsersWinnerList({ 'axios': this.axios });
      this.winner = StateStore.getters.getUsersWinnerList()?.length;
    }
  },
  async mounted() {
    await this.getData()
  }
};
</script>
<style>
.only {
  background: linear-gradient(to right, #975067, #40091A) !important;
}
</style>