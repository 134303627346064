<template>
  <div class="row">
    <div class="col-lg-12 col-xxxl-12 col-md-12">
      <WhatHappening :axios="$axios" />
    </div>
    <div class="col-lg-6 col-md-6 col-xxxl-6">
      <StatsBoxes :axios="$axios" />
    </div>
    <div class="col-lg-6 col-md-6 col-xxxl-6">
      <ExpectedEarnings :axios="$axios" />
    </div>
    <div class="col-lg-12 col-xxxl-12 col-md-12">
      <AverageDailySales :axios="$axios" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WhatHappening from "../../components/Dashboard/Ecommerce/WhatHappening.vue";
import ExpectedEarnings from "../../components/Dashboard/Ecommerce/ExpectedEarnings.vue";
import AverageDailySales from "../../components/Dashboard/Ecommerce/AverageDailySales.vue";
import StatsBoxes from "../../components/Dashboard/Ecommerce/StatsBoxes/index.vue";

export default defineComponent({
  name: "EcommercePage",
  components: {
    WhatHappening,
    ExpectedEarnings,
    AverageDailySales,
    StatsBoxes,
  },
});
</script>
