<template>
  <div ref="mymodal" class="modal fade createNewModal" id="createNewLevelModal" tabindex="-1" aria-hidden="true"
    @click.self="handleBackdropClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Nivel</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form @submit.prevent="submitForm">
            <div class="row mb-5">
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group md-25', { 'has-error': !item.name && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Número del nivel
                  </label>
                  <input v-model="item.name" type="number" class="form-control shadow-none rounded-0 text-black"
                    :required="true" min="1" @input="validateLevelNumber" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group md-25', { 'has-error': !item.game && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Juego
                  </label>
                  <select v-model="item.game" class="form-control shadow-none rounded-0 text-black"
                    style="height: 46px;" :required="true" @change="handleGameChange">
                    <option v-for="(gameOption, index) in games" :value="gameOption.id" :key="index">
                      {{ gameOption.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="error !== '' || showError">
              <div class="row error-msg" v-if="error != null || showError">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-30">
              <button
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="button" data-bs-dismiss="modal" aria-label="Close">
                Cancelar
              </button>
              <button
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15"
                type="submit" :disabled="showError" @click.prevent="submitForm">
                Crear
              </button>
            </div>
          </form>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";

export default {
  name: "CreateNewLevelModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    },
    id: {
      type: Number,
      required: false,
    },
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: '',
      showError: false,
      maxLen: 10,
      minLen: 8,
      item: {
        name: null,
        game: null
      },
      games: []
    };
  },
  watch: {
    async id(newVal) {
      // console.log('newVal: ', newVal)
      if (newVal > 0) {
        await StateStore.actions.actionsGetLevel({ 'axios': this.axios, 'id': newVal });
        this.item = await StateStore.getters.getLevelRetrieve();
        // console.log("itemRetrieve: ", this.item);
      } else if (newVal === undefined) {
        this.item = [];
      }
    }
  },
  mounted() {
    this.$refs.mymodal.addEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  methods: {
    handleModalHidden() {
      this.$emit('modal-closed');
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    async getAllGames() {
      await StateStore.actions.actionsGetAllGameList({ 'axios': this.axios });
      this.games = StateStore.getters.getGameList();
    },
    validateLevelNumber() {
      const number = this.item.name;
      if (isNaN(number) || number <= 0 || parseInt(this.item.name) !== this.item.name) {
        this.showError = true;
        this.error = 'El número de nivel debe ser un entero positivo.';
      } else {
        this.showError = false;
        this.error = '';
      }
    },
    handleGameChange() {
      this.error = '';
      this.showError = false;
    },
    async submitForm() {
      this.showError = true;
      if (!this.item.name || !this.item.game) {
        this.error = 'Todos los campos son necesarios';
      } else {
        this.error = '';
        this.showError = false;
        if (this.id === undefined) {
          // console.log('this.id: ', this.id);
          await StateStore.actions.actionsCreateLevel({
            'axios': this.$axios,
            'name': this.item.name,
            'game': this.item.game,
          })
            .then((response) => {
              if (response?.status === 200 || stateStore.ok) {
                location.reload();
              } else {
                this.error = 'El nivel que desea crear ya existe.'
                this.showError = true
              }
            }).catch((error) => {
              this.error = 'Ocurrió un error. Revise su conexión a internet e inténtelo de nuevo.'
              this.showError = true
              // Maneja cualquier error que pueda ocurrir durante la ejecución
              console.error('Error al crear el nivel:', error?.[0]?.messages);
            });
        } else {
          await StateStore.actions.actionsEditLevel({
            'axios': this.$axios,
            'id': this.item.id,
            'name': this.item.name,
            'game': this.item.game,
          })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                // Maneja el error
                console.error('Error al editar el Nivel:', response);
              }
            }).catch((error) => {
              // Maneja cualquier error que pueda ocurrir durante la ejecución
              console.error('Error al editar el Nivel:', error);
            });
        }
        // console.log('Formulario enviado:', this.item);
      }
    },
  },
  created() {
    // console.log("props item: ", this.item);
    this.getAllGames();
  },
};
</script>