import { AxiosInstance } from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Routes {
  static async retrieve(axios: AxiosInstance, id: number | string | null) {
    const response = await axios.post(
      '/graphql/',
      `query{
                  routeRetrieve(id:${id}){
                    id
                    name
                    date
                    cities{
                      id
                      name
                      state{
                          id
                          name
                      }
                    }
                  }
                }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        return Promise.resolve(null);
      } else if (error.request) {
        return Promise.resolve(null);
      }
    })
    if (response) {
      return response.data.data.routeRetrieve;
    }
  }

  static async all(axios: AxiosInstance) {
    const response = await axios?.post(
      "/graphql/",
      `query{
                  routeList{
                    id
                    name
                    date
                    cities{
                      id
                      name
                      state{
                          id
                          name
                      }
                    }
                  }
                }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    );

    return response.data.data?.routeList;
  }


  static async delete(axios: AxiosInstance, id: number) {
    const response = await axios.post(
      "/graphql/",
      {
        query: `mutation deleteRoute ($id: ID!){
                      deleteRoute(id: $id){
                        ok
                      }
                    }`,
        variables: {
          id: id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data.deleteRoute.ok;
  }

  static async create(
    axios: AxiosInstance,
    name: number,
    date: any,
    cities: number[],
  ) {
    // Convertir la fecha al formato "YYYY-MM-DD"
    const dateObj = new Date(date);
    const formattedDate = dateObj.toISOString().split('T')[0];

    const response = await axios?.post(
      "/graphql/",
      `mutation {
        createRoute(newRoute: {
          name: ${name}
          date: "${formattedDate}"
          cities: [${cities.join(',')}]
        }) {
          ok
          errors {
            field
            messages
          }
          route {
            id
            name
            date
            cities {
              id
              name
              state {
                id
                name
              }
            }
          }
        }
      }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        console.error(error.response.data);
        return Promise.resolve(null);
      } else if (error.request) {
        return Promise.resolve(null);
      }
    });

    if (response && response.data && response.data.data) {
      if (response.data.data.createRoute?.ok) {
        return response.data.data.createRoute.ok;
      } else if (response.data.data.createRoute?.errors) {
        const errores = response.data.data.createRoute.errors;
        const mensajes: string[] = [];
        for (let i = 0; i < errores?.length; i++) {
          const error = errores[i];
          for (let j = 0; j < error.messages?.length; j++) {
            mensajes.push(error.messages[j]);
          }
        }
        StateStore.actions.actionsSetErrorMessage(mensajes);
        return null;
      } else if (response.data.errors) {
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } else {
      return null;
    }
  }

  static async edit(
    axios: AxiosInstance,
    id: number,
    name: number,
    date: any,
    cities: number[],
  ) {
    // Convertir la fecha al formato "YYYY-MM-DD"
    const dateObj = new Date(date);
    const formattedDate = dateObj.toISOString().split('T')[0];

    const response = await axios?.post(
      "/graphql/",
      `mutation{
        updateRoute(newRoute:{
          id:${id}
          name:${name}
          date: "${formattedDate}"
          cities: [${cities.join(',')}]
        }){
          ok
          errors{
            field
            messages
          }
          route {
            id
            name
            date
            cities {
              id
              name
              state {
                id
                name
              }
            }
          }
        }
      }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        console.error(error.response.data);
        return Promise.resolve(null);
      } else if (error.request) {
        return Promise.resolve(null);
      }
    });

    if (response && response.data) {
      if (response.data.data.updateRoute.ok) {
        return response.data.data.updateRoute.ok;
      } else if (response.data.data.updateRoute.errors) {
        const errores = response.data.data.updateRoute.errors;
        const mensajes: string[] = [];

        for (let i = 0; i < errores?.length; i++) {
          const error = errores[i];
          for (let j = 0; j < error.messages?.length; j++) {
            mensajes.push(error.messages[j]);
          }
        }
        StateStore.actions.actionsSetErrorMessage(mensajes);
        return null;
      } else if (response.data.errors) {
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } else {
      return null;
    }
  }
}
