import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Game {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                  gameRetrieve(id:${id}){
                    id
                    name
                    default
                    levels{
                      id
                      name
                      questions{
                        id
                        name
                        point
                        answers{
                          id
                          name
                          value
                          correct
                        }
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.gameRetrieve
            ) {
                response.data.data.gameRetrieve.compressed = {
                    levels: response.data.data.gameRetrieve.levels,
                };
            }

            // response.data.data.gameRetrieve.section = response.data.data.gameRetrieve?.section?.id

            return response.data.data.gameRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  gameList{
                    id
                    name
                    default
                    levels{
                      id
                      name
                      questions{
                        id
                        name
                        point
                        answers{
                          id
                          name
                          value
                          correct
                        }
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("gameList: ", response.data.data?.gameList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.gameList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteGame ($id: ID!){
                      deleteGame(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteGame.ok;
    }

    static async create(
axios: AxiosInstance, name: string, isDefault: boolean,
        // section: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                      createGame(newGame:{
                        name:"${name}"
                        default:${isDefault}
                      }){
                        game{
                          id
                          name
                        }
                        ok
                        errors{
                          field
                          messages
                        }
                      }
                    }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createGame.ok) {
                return response.data.data.createGame.ok;
            } else if (response.data.data.createGame.errors) {
                const errores = response.data.data.createGame.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createGame.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
axios: AxiosInstance, id: number, name: string, isDefault: boolean,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateGame(newGame:{
                    id:${id}
                    name:"${name}"
                    default:${isDefault}
                  }){
                    game{
                      id
                      name
                      default
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateGame.ok) {
                return response.data.data.updateGame.ok;
            } else if (response.data.data.updateGame.errors) {
                const errores = response.data.data.updateGame.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateGame.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
