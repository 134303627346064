import { AxiosInstance } from 'axios'; // Importa axios

export default class State {
    // Método para obtener la lista de estados
    static async all(axios: AxiosInstance) {
        const response = await axios.post(
            '/graphql/',
            `query{
                stateList {
                    id
                    name
                    cities {
                        id
                        name
                    }
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // Manejar error de respuesta
                return Promise.resolve(null);
            } else if (error.request) {
                // Manejar error de solicitud
                return Promise.resolve(null);
            }
        });

        if (response && response.data) {
            return response.data.data.stateList;
        } else {
            return null;
        }
    }

    // Método para obtener un estado específico por ID
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                stateRetrieve(id: ${id}) {
                    id
                    name
                    cities {
                        id
                        name
                    }
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });

        if (response && response.data) {
            return response.data.data.stateRetrieve;
        } else {
            return null;
        }
    }
}
