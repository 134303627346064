<template>
  <div class="card mb-25 border-0 rounded-0 bg-white daily-sales-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" style="min-height: 205px;">
      <span class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis">
        Ganadores por Ciudad
      </span>
      <h1 class="fw-black mb-12 lh-1 mt-30">{{ totalOtherWinners }}</h1>
      <div class="chart">
        <apexchart type="bar" height="150" id="winnerPerCityChart" :options="winnerPerCityChart"
          :series="winners" :key="chartKey">
        </apexchart>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";

interface CityWinnerData {
  name: string;
  data: number[];
}

export default defineComponent({
  name: "AverageDailySales",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  data() {
    return {
      winners: [] as CityWinnerData[],
      totalOtherWinners: 0,
      chartKey: 0,
      winnerPerCityChart: {
        chart: {
          type: "bar",
          height: 150,
          width: 220,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: "35%",
            borderRadiusApplication: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#6FD3F7"],
        stroke: {
          show: false,
        },
        xaxis: {
          categories: [] as string[],
          labels: {
            show: true,
            style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: "#9C9AB6",
              fontSize: "14px",
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          tickAmount: 8,
          labels: {
            show: true,
            style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: ["#9C9AB6"],
              fontSize: "14px",
              fontWeight: 500,
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
          y: {
            formatter: function (val: string) {
              return val;
            },
          },
        },
        grid: {
          show: false,
          strokeDashArray: 5,
          borderColor: "#f0f0f0",
        },
      },
    };
  },
  async created() {
    await this.getAllOtherCityWinners();
  },
  methods: {
    async getAllOtherCityWinners() {
      await StateStore.actions.actionsGetAllUsersWinnerList({ 'axios': this.axios });
      const winners = StateStore.getters.getUsersWinnerList();

      // Procesar los datos para obtener la cantidad de ganadores por ciudad
      const otherCityCounts: Record<string, number> = {};
      const excludedCities = ["Ensenada", "Playas de Rosarito"];

      winners?.forEach(user => {
        const userCity = user.city?.name;
        if (!excludedCities.includes(userCity)) {
          otherCityCounts[userCity] = (otherCityCounts[userCity] || 0) + 1;
          this.totalOtherWinners += 1;
        }
      });

      const categories = Object.keys(otherCityCounts);
      const dataValues = categories.map(city => otherCityCounts[city]);

      this.winners = [{ name: "Ganadores", data: dataValues }];

      this.winnerPerCityChart.xaxis.categories = categories;
      this.chartKey += 1;
    }
  },
});
</script>
