<template>
  <div :class="[
    'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
    { active: stateStoreInstance.open },
  ]" id="sidebar-area">
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link to="/" class="d-flex  align-items-center text-white text-decoration-none">
        <img src="../../assets/images/logo1.png" alt="logo-icon" style="height: 90px; width: 150px" />
      </router-link>
      <button class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange">
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion" id="sidebarNavAccordion">
        <li class="sidebar-nav-item bg-transparent">
          <router-link to="/" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
          <a href="#" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse" data-bs-target="#sidebarCollapseTwo" aria-expanded="false"
            aria-controls="sidebarCollapseTwo">
            <i class="flaticon-setting"></i>
            <span class="title">Administración</span>
          </a>
          <div id="sidebarCollapseTwo" class="accordion-collapse collapse" data-bs-parent="#sidebarNavAccordion">
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled bg-transparent">
                <li class="sidebar-sub-menu-item bg-transparent">
                  <router-link to="/users-list" class="sidebar-sub-menu-link bg-transparent">
                    Usuarios
                  </router-link>
                  <router-link to="/route-list" class="sidebar-sub-menu-link bg-transparent">
                    Rutas
                  </router-link>
                  <router-link to="/app-version-list" class="sidebar-sub-menu-link bg-transparent"
                    v-if="currentUserJson?.roles == 3">
                    Versionado de la Aplicación
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
          <a href="#" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse" data-bs-target="#sidebarCollapseNine" aria-expanded="false"
            aria-controls="sidebarCollapseNine">
            <i class="flaticon-trophy"></i>
            <span class="title">Juegos</span>
          </a>
          <div id="sidebarCollapseNine" class="accordion-collapse collapse" data-bs-parent="#sidebarNavAccordion">
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/game-list" class="sidebar-sub-menu-link bg-transparent">
                    Gestionar Juego
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/games-user-list" class="sidebar-sub-menu-link bg-transparent">
                    Listado de Jugadores
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/games-winner-list" class="sidebar-sub-menu-link bg-transparent">
                    Listado de Ganadores
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/levels-list" class="sidebar-sub-menu-link bg-transparent">
                    Gestionar Niveles
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/questions-list" class="sidebar-sub-menu-link bg-transparent">
                    Gestionar Preguntas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/answers-list" class="sidebar-sub-menu-link bg-transparent">
                    Posibles Respuestas
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
          <a href="#" class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse" data-bs-target="#sidebarCollapseTwelve" aria-expanded="false"
            aria-controls="sidebarCollapseTwelve">
            <i class="flaticon-book"></i>
            <span class="title">Secciones Educativas</span>
          </a>
          <div id="sidebarCollapseTwelve" class="accordion-collapse collapse" data-bs-parent="#sidebarNavAccordion">
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/educ-sections-list" class="sidebar-sub-menu-link bg-transparent">
                    Secciones Educativas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/topic-list" class="sidebar-sub-menu-link bg-transparent">
                    Agregar Tópicos
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title" @click.prevent="logout">Cerrar Sesión</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";
import Swal from "sweetalert2";

export default defineComponent({
  name: "MainSidebar",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  setup(props) {
    const stateStoreInstance = stateStore;
    const refreshToken = localStorage.getItem("refreshToken");
    const currentUser = stateStore.currentUser;
    let currentUserJson = {};
    if (typeof currentUser === 'object' && currentUser !== null) {
      currentUserJson = JSON.stringify(currentUser);
      // console.log(currentUserJson);
    } else {
      currentUserJson = JSON.parse(currentUser);
      // console.log("currentUser: ", currentUserJson?.roles);
    }
    return {
      stateStoreInstance,
      currentUserJson,
      async logout() {
        // Muestra un cuadro de confirmación utilizando SweetAlert2
        const result = await Swal.fire({
          title: "¿Está seguro de que desea cerrar sesión?",
          text: "No podrás deshacer esta acción.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, cerrar sesión",
          cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
          // console.log("El usuario confirmó: ", refreshToken);

          await StateStore.actions.actionsLogout({
            axios: props.axios,
            refreshToken: refreshToken,
          });

          // Recarga la página después de cerrar sesión
          location.reload();
        } else {
          // console.log("El usuario canceló");
        }
      },
    };
  },
});
</script>
