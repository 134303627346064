<template>
  <div ref="mymodal" class="modal fade createNewModal" id="CreateNewEducSectionModal" tabindex="-1" aria-hidden="true"
    @click.self="handleBackdropClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Sección Educativa</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form>
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.name && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Nombre
                  </label>
                  <input v-model="item.name" type="text" class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Sección Educativa" />
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.ico && showError }]">
                  <label class="text-black fw-semibold mb-10">
                    Iconos 3D
                  </label>
                  <input ref="fileInput" type="file" class="form-control shadow-none rounded-0 text-black"
                    @change="handleFileChange" />
                  <!-- Mostrar previsualización de la imagen -->
                  <small v-if="selectedFileName" class="text-muted">
                    <div v-if="imageUrls.length > 0">
                      <img :src="imageUrls[0]" alt="Imagen actual" class="img-thumbnail"
                        style="max-width: 100px; max-height: 100px; margin-top: 10px;">
                    </div>
                  </small>
                </div>
              </div>
            </div>
            <div v-if="error !== '' || showError">
              <div class="row" v-if="error != null || showError">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div v-if="success">
              <div class="row" v-if="success">
                <span class="success-message ms-2 mb-6 me-6">{{ message }}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between;">
              <button
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="button" data-bs-dismiss="modal" aria-label="Close">
                Cancelar
              </button>
              <button
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15"
                type="submit" :disabled="showError" @click.prevent="submitForm">
                {{ title }} Sección Educativa
              </button>
            </div>
          </form>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";

export default {
  name: "CreateNewEducSectionModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    },
    id: {
      type: Number,
      required: false,
    },
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: '',
      success: false,
      message: '',
      showError: false,
      maxLen: 10,
      minLen: 8,
      imageUrls: [],
      iconId: [],
      bucketName: process.env.VUE_APP_BUCKET_NAME,
      selectedFileName: '',
      initialFileName: '',
      item: {
        name: '',
        ico: [],
        value: null,
        question: null,
        correct: null,
      },
      questions: []
    };
  },
  watch: {
    async id(newVal) {
      if (newVal > 0) {
        await StateStore.actions.actionsGetEducSections({ 'axios': this.axios, 'id': newVal });
        this.item = await StateStore.getters.getEducationalSectionsRetrieve();

        // Si hay un ícono asociado, extraer el nombre y URL del archivo para mostrarlo
        if (this.item.ico && this.item.ico.length > 0) {
          this.selectedFileName = this.item.ico[0]?.name || '';
          this.initialFileName = this.item.ico[0]?.name || '';
          this.imageUrls = [`https://${this.bucketName}.s3.amazonaws.com/Files/${this.item.ico[0]?.name}`]; // Asigna el URL de la imagen actual
        } else {
          this.selectedFileName = '';
          this.imageUrls = [];
        }
      } else if (newVal === undefined) {
        this.item = [];
        this.selectedFileName = '';
        this.imageUrls = [];
      }
    }
  },
  mounted() {
    this.$refs.mymodal.addEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  methods: {
    validatePassword() {
      if (this.password !== this.password2) {
        this.showError = true;
        this.error = 'Las contraseñas no coinciden';
        this.alert = true;
        // console.log("password don't match");
      } else {
        this.error = '';
        this.alert = false;
        this.showError = false;
        // console.log("password match");
      }
    },
    checkMinLength(field, minLen) {
      if (field === 'phone' && this.item[field] && (this.item[field].toString())?.length < minLen) {
        this.error = `El campo debe tener más de ${minLen ? minLen : this.minLen} caracteres`;
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item[field] && this.item[field]?.length < (minLen ? minLen : this.minLen)) {
        this.error = `El campo debe tener más de ${minLen ? minLen : this.minLen} caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (field === 'phone') {
        if (isNaN(this.item[field])) {
          this.showError = true;
          this.error = `Número de Teléfono invalido, rectifiquelo`;
        }
        if (this.item && this.item[field] && (this.item[field].toString())?.length > maxLen) {
          this.error = `El campo no puede tener más de ${maxLen ? maxLen : this.maxLen} caracteres`;
          this.showError = true;
        }
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item && this.item[field] && this.item[field]?.length >= (maxLen ? maxLen : this.maxLen)) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${maxLen ? maxLen : this.maxLen} caracteres`;
      } else {
        this.showError = false;
        this.error = '';
      }
    },
    handleModalHidden() {
      this.clearFormData();
      this.$emit('modal-closed');
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    clearFormData() {
      this.item = {
        name: '',
        ico: [],
        value: null,
        question: null,
        correct: null,
      };
      this.imageUrls = [];
      this.selectedFileName = '';
      this.error = '';
      this.success = false;
      this.showError = false;
      // Limpiar el campo de archivo
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
    },
    async getData() {
      await StateStore.actions.actionsGetAllQuestionsList({ 'axios': this.axios });
      this.questions = StateStore.getters.getQuestionsList();
    },
    handleFileChange(event) {
      const file = event.target.files[0]; // Obtener el primer archivo seleccionado
      if (file) {
        // Limpiar la URL de la imagen actual y agregar la nueva imagen seleccionada
        this.selectedFileName = file.name;
        this.imageUrls = [URL.createObjectURL(file)]; // Mostrar la imagen seleccionada
      } else {
        // Si no se selecciona ninguna imagen, vaciar la previsualización
        this.imageUrls = [];
        this.selectedFileName = '';
      }

      this.item.ico = Array.from(event.target.files); // Mantener el arreglo de archivos
    },
    async submitForm() {
      this.showError = true;
      if (!this.item.name) {
        this.error = 'Todos los campos son necesarios';
        return;
      } else {
        this.error = '';
        this.showError = false;
        this.success = true;
        if (this.item.id > 0) {
          this.message = 'Sección Educativa Modificada con éxito...';
        } else {
          this.message = 'Sección Educativa Creada con éxito...';
        }

        await this.uploadImages();

        if (this.item.id === undefined) {

          await StateStore.actions.actionsCreateEducSections({
            'axios': this.$axios,
            'name': this.item.name,
            'ico': this.iconId,
          })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                console.error('Error al crear la Respuesta:', response);
              }
            }).catch((error) => {
              console.error('Error al crear el usuario:', error);
            });
        } else {
          await StateStore.actions.actionsEditEducSections({
            'axios': this.$axios,
            'id': this.item.id,
            'name': this.item.name,
            'ico': this.iconId
          })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                console.error('Error al editar la Respuesta:', response);
              }
            }).catch((error) => {
              console.error('Error al editar la Respuesta:', error);
            });
        }
      }
    },
    async uploadImages() {
      if (!this.item.ico || this.item.ico.length === 0) return;

      if (this.initialFileName && this.initialFileName === this.selectedFileName) {
        this.iconId = [this.item.ico[0].id]; // Usa el ícono actual
        return
      }

      const formData = new FormData();
      this.item.ico.forEach((file) => {
        formData.append('file', file);
      });
      try {
        const response = await api.post('/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response !== null) {
          this.iconId = response.data
        } else {
          console.error("Error al subir el ícono.");
        }
      } catch (error) {
        console.error("Error al subir el ícono:", error);
      }
    }
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.form-group {
  position: relative;
}

.form-control {
  padding-right: 40px;
  /* Espacio para el botón */
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  /* Ajusta esto según sea necesario */
}

.d-block {
  color: #FFFFFF !important;
}

.has-error input,
.has-error select {
  border-color: red;
}

.success-message {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}
</style>