import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12 col-xxxl-12 col-md-12" }
const _hoisted_3 = { class: "col-lg-6 col-md-6 col-xxxl-6" }
const _hoisted_4 = { class: "col-lg-6 col-md-6 col-xxxl-6" }
const _hoisted_5 = { class: "col-lg-12 col-xxxl-12 col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")!
  const _component_ExpectedEarnings = _resolveComponent("ExpectedEarnings")!
  const _component_AverageDailySales = _resolveComponent("AverageDailySales")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WhatHappening, { axios: _ctx.$axios }, null, 8, ["axios"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_StatsBoxes, { axios: _ctx.$axios }, null, 8, ["axios"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ExpectedEarnings, { axios: _ctx.$axios }, null, 8, ["axios"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AverageDailySales, { axios: _ctx.$axios }, null, 8, ["axios"])
    ])
  ]))
}