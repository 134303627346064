<template>
  <BreadCrumb :PageGrandParent="pageGrandParent" :PageParent="pageParent" PageTitle="Perfil"
    grandParentRoute="/users-list" parentRoute="/users-list" />
  <div class="row">
    <div class="col-xxl-7">
      <ProfileContent :axios="$axios" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ProfileContent from "../../components/PagesInner/Profile/ProfileContent.vue";

export default defineComponent({
  name: "ProfilePage",
  components: {
    BreadCrumb,
    ProfileContent,
  },
  setup() {
    const router = useRouter();

    // Obtener la ruta previa desde el historial
    const previousRoute = router.options.history.state.back;

    // Establecer el valor de PageParent dependiendo de la ruta anterior
    const pageGrandParent = previousRoute === "/users-list" ? "Administración" : null;
    const pageParent = previousRoute === "/users-list" ? "Usuarios" : null;

    return {
      pageParent,
      pageGrandParent
    };
  },
});
</script>
