<template>
  <div class="row">
    <div class="col-xxl-8 order-2 order-xl-1">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing settings-card"
      >
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <form @submit.prevent="handleChangePassword">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Contraseña anterior
              </label>
              <div class="password-input">
                <input
                  id="old_password"
                  :type="isOldPasswordVisible ? 'text' : 'password'"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="oldPassword"
                  placeholder="Contraseña actual"
                  autocomplete="off"
                  required
                  style="padding-right: 2.5rem"
                  @input="checkConfirmPassword"
                />
                <button
                  type="button"
                  class="toggle-visibility"
                  @click="toggleOldPasswordVisibility"
                >
                  <img
                    class="input-right-icon"
                    :src="
                      isOldPasswordVisible
                        ? 'https://cdn-icons-png.flaticon.com/128/10812/10812267.png'
                        : 'https://cdn-icons-png.flaticon.com/128/159/159604.png'
                    "
                    alt="Toggle Password Visibility"
                    width="25"
                    height="25"
                    style="filter: invert(0.4)"
                  />
                </button>
              </div>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nueva contraseña
              </label>
              <div class="password-input">
                <input
                  id="new_password"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="newPassword"
                  placeholder="Contraseña nueva"
                  autocomplete="off"
                  required
                  style="padding-right: 2.5rem"
                  @input="checkNewPassword"
                />
                <button
                  type="button"
                  class="toggle-visibility"
                  @click="toggleNewPasswordVisibility"
                >
                  <img
                    class="input-right-icon"
                    :src="
                      isNewPasswordVisible
                        ? 'https://cdn-icons-png.flaticon.com/128/10812/10812267.png'
                        : 'https://cdn-icons-png.flaticon.com/128/159/159604.png'
                    "
                    alt="Toggle Password Visibility"
                    width="25"
                    height="25"
                    style="filter: invert(0.4)"
                  />
                </button>
              </div>
              <span v-if="invalidNewPassword" class="text-danger"
                >La contraseña debe tener al menos 8 caracteres y debe incluir
                letras y números</span
              >
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Confirmar Contraseña
              </label>
              <div class="password-input">
                <input
                  id="check_new_password"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="confirmPassword"
                  placeholder="Confirmar contraseña nueva"
                  autocomplete="off"
                  required
                  style="padding-right: 2.5rem"
                  @input="checkConfirmPassword"
                />
                <button
                  type="button"
                  class="toggle-visibility"
                  @click="toggleConfirmPasswordVisibility"
                >
                  <img
                    class="input-right-icon"
                    :src="
                      isConfirmPasswordVisible
                        ? 'https://cdn-icons-png.flaticon.com/128/10812/10812267.png'
                        : 'https://cdn-icons-png.flaticon.com/128/159/159604.png'
                    "
                    alt="Toggle Password Visibility"
                    width="25"
                    height="25"
                    style="filter: invert(0.4)"
                  />
                </button>
              </div>
              <span v-if="passwordMismatch" class="text-danger"
                >Las contraseñas no coinciden</span
              >
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button
                type="button"
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                @click="handleCancel"
              >
                <i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"
                ></i>
                <span class="position-relative">Cancel</span>
              </button>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Cambiar Contraseña
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showError: false,
      isOldPasswordVisible: false,
      isNewPasswordVisible: false,
      isConfirmPasswordVisible: false,
      passwordMismatch: false,
      invalidNewPassword: false,
    };
  },
  methods: {
    toggleOldPasswordVisibility() {
      this.isOldPasswordVisible = !this.isOldPasswordVisible;
    },
    toggleNewPasswordVisibility() {
      this.isNewPasswordVisible = !this.isNewPasswordVisible;
    },
    toggleConfirmPasswordVisibility() {
      this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    },

    checkConfirmPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },

    checkNewPassword() {
      this.checkConfirmPassword();
      if (
        this.newPassword.length < 8 ||
        !/\d/.test(this.newPassword) ||
        !/[a-zA-Z]/.test(this.newPassword)
      ) {
        this.invalidNewPassword = true;
      } else {
        this.invalidNewPassword = false;
      }
    },
    async handleChangePassword() {
      if (this.newPassword !== this.confirmPassword) {
        return; // Si las contraseñas no coinciden no hacer nada
      }

      const result = await Swal.fire({
        title: "¿Estás seguro de que quieres cambiar la contraseña?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, cambiar contraseña",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        await StateStore.actions.actionsChangePassword({
          axios: this.axios,
          oldPassword: this.oldPassword,
          newPassword1: this.newPassword,
          newPassword2: this.confirmPassword,
        });
      }
    },
    handleCancel() {
      this.$router.push("/"); // Redirige al dashboard
    },
  },
};
</script>

<style scoped>
.password-input {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.password-input .toggle-visibility {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}
</style>
