<template>
  <div ref="mymodal" class="modal fade createNewModal" id="createNewRouteModal" tabindex="-1" aria-hidden="true"
    @click.self="handleBackdropClick">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title fw-bold text-black">{{ title }} Ruta</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.name && showError }]">
                  <label class="form-label fw-semibold">Número</label>
                  <input v-model="item.name" type="number" class="form-control" placeholder="1" min="1"
                    @input="clearError" />
                  <span v-if="!item.name && showError" class="text-danger">Este campo es requerido.</span>
                </div>
              </div>
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.date && showError }]">
                  <label class="form-label fw-semibold">Fecha de recordatorio</label>
                  <input v-model="item.date" type="date" class="form-control" @input="clearError" />
                  <span v-if="!item.date && showError" class="text-danger">Este campo es requerido.</span>
                </div>
              </div>
              <div class="col-md-4">
                <div :class="['form-group mb-3', { 'has-error': !item.state && showError }]">
                  <label class="form-label fw-semibold">Estado</label>
                  <select v-model="item.state" class="form-control" @input="clearError" @change="filterCities">
                    <option disabled value="">Seleccione un estado</option>
                    <option v-for="(state, index) in states" :value="state" :key="index">
                      {{ state.name }}
                    </option>
                  </select>
                  <span v-if="!item.city && showError" class="text-danger">Seleccione una opción.</span>
                </div>
              </div>
              <div class="col-md-8">
                <div :class="['form-group mb-3', { 'has-error': !item.cities && showError }]">
                  <label class="form-label fw-semibold">Ciudades</label>
                  <multiselect v-model="item.cities" :options="filteredCities" :multiple="true" :searchable="true"
                    :placeholder="multiselectPlaceholderText" :track-by="'id'" :label="'name'" :closeOnSelect="false"
                    @input="clearError" :selectLabel="'Presiona Enter para seleccionar'"
                    :deselectLabel="'Presiona Enter para deseleccionar'" :selectedLabel="'seleccionado'"
                    @open="onMultiselectOpen" @close="onMultiselectClose">
                    <!-- Slot para cuando no hay opciones -->
                    <template #noOptions>
                      <span>{{
                        !item.state
                          ? "Seleccione un estado para ver sus ciudades"
                          : "No hay opciones disponibles"
                      }}</span>
                    </template>
                    <!-- Slot para cuando no hay resultados de búsqueda -->
                    <template #noResult>
                      <span>No se encontraron resultados</span>
                    </template>
                  </multiselect>
                  <span v-if="!item.cities || item.cities.length === 0 && showError" class="text-danger">Seleccione al
                    menos una opción.</span>
                </div>
              </div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
            <div v-if="success" class="alert alert-success mt-3">{{ message }}</div>
            <div class="modal-footer" style="display: flex; justify-content: space-between;">
              <button type="button"
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1"
                data-bs-dismiss="modal" @click="closeModal">Cancelar</button>
              <button type="submit"
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1">
                {{ title }} Ruta
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "CreateNewRouteModal",
  components: {
    Multiselect,
  },
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    id: Number,
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true,
    },
    existingRoutes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      showError: false,
      success: false,
      message: '',
      item: {
        id: null,
        name: null,
        date: null,
        state: "",
        cities: [],
      },
      states: [],
      filteredCities: [],
      multiselectPlaceholderText: "Seleccione una o varias ciudades",
    };
  },
  watch: {
    id(newId) {
      if (newId) {
        this.getRouteData(newId);
      } else if (newId === undefined) {
        this.item = {
          id: null,
          name: null,
          date: null,
          state: "",
          cities: [],
        };
      }
    },
  },
  methods: {
    async getStatesData() {
      try {
        await StateStore.actions.actionsGetAllStateList({ axios: this.axios });
        this.states = StateStore.getters.getStateList();
      } catch (error) {
        console.error('Error al obtener las ciudades:', error);
        this.error = 'Error al cargar las ciudades.';
      }
    },
    filterCities() {
      this.filteredCities = this.item.state ? this.item.state.cities : [];
      this.clearError();
    },
    async getRouteData(id) {
      if (!id) return;
      try {
        await StateStore.actions.actionsGetRoutes({ axios: this.axios, id });
        this.item = await StateStore.getters.getRoutesRetrieve();
        this.item.cities = this.item.cities || [];
      } catch (error) {
        console.error('Error al obtener la ruta:', error);
        this.error = 'Error al cargar los datos de la ruta.';
      }
    },
    async submitForm() {
      this.showError = true;
      if (!this.item.name || !this.item.date || this.item.state.length === 0 || this.item.cities.length === 0) {
        this.error = 'Rellene los campos requeridos';
        return;
      }

      // Convertir item.cities en un arreglo de IDs de ciudades
      this.item.cities = this.item.cities.map(city => city.id);


      // Verificar si el número de ruta ya existe
      let existingRouteNumbers = this.existingRoutes.map(route => route.name);

      // Si estamos editando, excluimos la ruta actual de la validación
      if (this.id) {
        existingRouteNumbers = existingRouteNumbers.filter(routeName => routeName !== this.item.name);
      }

      if (existingRouteNumbers.includes(this.item.name)) {
        this.error = 'El número de ruta ya existe.';
        return;
      }

      this.error = '';
      this.showError = false;
      this.success = true;
      try {
        if (!this.id) {
          // Creación
          await StateStore.actions.actionsCreateRoutes({
            axios: this.axios,
            name: this.item.name,
            date: this.item.date,
            cities: this.item.cities,
          });
          this.message = 'Ruta creada con éxito...';
        } else {
          // Edición
          await StateStore.actions.actionsEditRoutes({
            axios: this.axios,
            id: this.id,
            name: this.item.name,
            date: this.item.date,
            cities: this.item.cities,
          });
          this.message = 'Ruta modificada con éxito...';
        }
        this.$emit('route-saved');
        this.closeModal();
        location.reload();
      } catch (error) {
        console.error('Error al procesar la solicitud:', error);
        this.error = 'Ocurrió un error al procesar la solicitud.';
      }
    },
    clearError() {
      this.error = '';
    },
    handleBackdropClick() {
      if (this.noCloseOnBackdrop) return;
      this.closeModal();
      this.resetForm();
    },
    resetForm() {
      this.item = {
        id: null,
        name: null,
        date: null,
        state: "",
        cities: [],
      };
      this.filteredCities = [];
      this.error = '';
      this.success = false;
      this.showError = false;
      this.message = '';
    },
    closeModal() {
      this.$refs.mymodal.classList.remove('show');
      document.body.classList.remove('modal-open');
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove(); // Eliminar el fondo del modal
      }
      this.resetForm();
      this.$emit('close');
    },
    onMultiselectOpen() {
      this.multiselectPlaceholderText = "Escriba aquí para buscar";
    },
    onMultiselectClose() {
      this.multiselectPlaceholderText = "Seleccione una o varias ciudades";
    },
  },
  async created() {
    await this.getStatesData();
    if (this.id) {
      this.getRouteData(this.id);
    }
  },
};
</script>

<style scoped>
.has-error input,
.has-error select {
  border-color: red;
}

.alert-success {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.alert-danger {
  background-color: red;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.textarea-spacing {
  line-height: 1.2;
  min-height: 150px;
  max-height: 5000px;
  overflow-y: auto;
}
</style>
